@import 'variables';
@import 'typography';
@import '~bootstrap/scss/bootstrap';

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary-color;
}

label {
  color: $primary-color;
  font-weight: 700;
}

input {
  border: 1px solid $primary-color;
}

button:focus,
a:focus {
  box-shadow: 0px 0px 0px 2px black;
}

.dropdown-menu a:focus {
  box-shadow: none;
}

.dropdown-item.active,
.dropdown-item:active {
  background: 0;
}

.pmView {
  td {
    white-space: nowrap;
  }
}

@media (max-width: 1200px) {
  .pmView {
    max-width: 100%;
  }
}
